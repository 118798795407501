<template>
    <a-modal :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'" style="top: 8px;" width="80%" v-model="visible" :footer="null" :maskClosable="false">
        <a-row :gutter="8">
            <a-col>
            <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:4}" :wrapper-col="{span:20}">
                <a-form-model-item label="单位名称" prop="name">
                    <a-input :disabled="handle == 'check'" v-model="rowData.name" placeholder="请输入单位名称"></a-input>
                </a-form-model-item>
              <a-form-model-item label="排序" prop="sort">
                <a-input :disabled="handle == 'check'" v-model="rowData.sort" placeholder="请输入排序"></a-input>
              </a-form-model-item>
              <a-form-model-item label="是否保留小数点" prop="flagPoint">
                <a-switch @click="onChangePoint" :disabled="handle == 'check'" v-model="rowData.flagPoint" checked-children="保留" un-checked-children="不保留" default-checked />
              </a-form-model-item>
                <a-form-model-item label="小数点位数" prop="" v-if="rowData.flagPoint == true">
                    <a-input-number :disabled="handle == 'check'" :min="1" :max="5" v-model="rowData.point"></a-input-number>
                </a-form-model-item>
            </a-form-model>
            </a-col>
        </a-row>
      <div class="footer-bts" v-if="handle != 'check'">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
      </div>
    </a-modal>
</template>

<script>
import { editProductGoodsUnit, selectByIdProductGoodsUnit, addProductGoodsUnit } from '../api/ProductGoodsUnitApi'
import {selectByIdProductCenter} from "@/views/product_center/api/productCenterApi";
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            loading: false,
            autoExpandParent: true,
            checkedKeys: [],
            selectedKeys: [],
            replaceFields: {
                children: 'children',
                title: 'name',
                key: 'id'
            },
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入规格名称', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {

      onChangePoint(val) {
        this.rowData.point = 1
        if(!val) {
          this.rowData.point = 0
        }
      },
      // 禁用弹框
      handleCancel() {
        this.$refs.form.resetFields();
        this.visible = false
      },
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
            this.checkedKeys = this.rowData.categoryId
            if(handle == 'add') {
                this.$set(this.rowData, 'flagPoint', false)
            }
            if(handle == 'add' ||handle=='check') {
                this.$set(this.rowData, 'flagPoint', false)
            }
          if (handle == 'check') {
            selectByIdProductGoodsUnit(id).then((res) => {
              this.rowData = res.body
            })
          }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                this.rowData.categoryId = this.checkedKeys
                const res = this.handle === 'add' ? await addProductGoodsUnit(this.rowData) : await editProductGoodsUnit(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        onCheck(checkedKeys, info) {
            this.checkedKeys = checkedKeys
        },
    },
    created() {
    },

}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
