/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductGoodsUnit = params => axios({
    url: '/api/product/productGoodsUnit/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const allProductGoodsUnit = params => axios({
  url: '/api/product/productGoodsUnit/selectAll',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const addProductGoodsUnit = params => axios({
    url: '/api/product/productGoodsUnit/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductGoodsUnit = params => axios({
    url: '/api/product/productGoodsUnit/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductGoodsUnit = params => axios({
    url:'/api/product/productGoodsUnit/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductGoodsUnit = params => axios({
    url: '/api/product/productGoodsUnit/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
