/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-19 14:40:07
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-20 21:04:42
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listAllGoods = params => axios({
    url: '/api/product/xcx/xcxProduct/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addOrEditProductCenter = params => axios({
    url: '/api/product/xcx/xcxProduct/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editOrEditProductCenter = params => axios({
    url: '/api/product/xcx/xcxProduct/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductGoods = params => axios({
    url:'/api/product/xcx/xcxProduct/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductCenter = params => axios({
    url: '/api/product/xcx/xcxProduct/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
